"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var react_router_1 = require("react-router");
var routes_1 = require("../../../../../navigation/routes");
var store_1 = require("../../../../../store");
var actions_1 = require("../../../store/login/actions");
var LoginOidCallbackPage = function () {
    var dispatch = (0, store_1.useAppDispatch)();
    var history = (0, react_router_1.useHistory)();
    (0, react_1.useEffect)(function () {
        var _a;
        var chunks = (_a = location === null || location === void 0 ? void 0 : location.hash) === null || _a === void 0 ? void 0 : _a.slice(1).split("&");
        var hashesMap = chunks === null || chunks === void 0 ? void 0 : chunks.reduce(function (acc, chunk) {
            var _a;
            var _b = chunk.split("="), key = _b[0], value = _b[1];
            return __assign((_a = {}, _a[key] = value, _a), acc);
        }, {});
        if (hashesMap === null || hashesMap === void 0 ? void 0 : hashesMap.access_token) {
            dispatch((0, actions_1.checkOIDAuth)(hashesMap.access_token)).then(function (_a) {
                var meta = _a.meta;
                var redirectUrl = localStorage.getItem("redirect_url");
                if (meta.requestStatus === "rejected") {
                    history.push(routes_1.routes.login);
                }
                else {
                    if (redirectUrl) {
                        history.replace("".concat(routes_1.routes.monitoringEvents).concat(redirectUrl));
                        localStorage.removeItem("redirect_url");
                    }
                    else {
                        history.push(routes_1.routes.monitoringEvents);
                    }
                }
            });
        }
    }, [dispatch]);
    return ((0, jsx_runtime_1.jsx)(material_1.Grid, __assign({ container: true, spacing: 0, direction: "column", alignItems: "center", justifyContent: "center", style: { minHeight: "100vh" } }, { children: (0, jsx_runtime_1.jsx)(material_1.Grid, __assign({ item: true, xs: 3 }, { children: (0, jsx_runtime_1.jsx)(material_1.CircularProgress, {}) })) })));
};
exports.default = LoginOidCallbackPage;
