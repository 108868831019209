"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var toolkit_1 = require("@reduxjs/toolkit");
var actions_1 = require("./actions");
var initialState = {
    isLoading: false,
    isLoggedIn: false,
    error: "",
    permissions: [],
};
var slice = (0, toolkit_1.createSlice)({
    name: "auth",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(actions_1.login.pending, function (state) {
            state.isLoading = true;
        });
        builder.addCase(actions_1.login.fulfilled, function (state) {
            state.isLoading = false;
        });
        builder.addCase(actions_1.login.rejected, function (state) {
            state.isLoading = false;
        });
        builder.addCase(actions_1.checkOIDAuth.rejected, function (state) {
            state.isLoading = false;
            state.error = "Произошла ошибка с получением прав";
        });
        builder.addCase(actions_1.checkOIDAuth.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.isLoading = false;
            state.isLoggedIn = true;
            state.permissions = payload;
            localStorage.setItem("permissions", payload.map(function (item) { return item.name; }).join());
        });
        builder.addCase(actions_1.logout, function (state) {
            state = initialState;
        });
    },
});
exports.default = slice.reducer;
