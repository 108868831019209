"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authService = exports.AuthOIDService = void 0;
var oidc_client_1 = require("oidc-client");
var AuthOIDService = /** @class */ (function () {
    function AuthOIDService() {
        this.userManager = new oidc_client_1.UserManager({});
        var settings = {
            authority: process.env.OID_AUTHORITY,
            client_id: process.env.OID_CLIENT_ID,
            client_secret: process.env.OID_CLIENT_SECRET,
            post_logout_redirect_uri: process.env.OID_REDIRECT_URI,
            redirect_uri: process.env.OID_REDIRECT_URI,
            response_type: "token",
            scope: "scoring_api",
            filterProtocolClaims: true,
            loadUserInfo: true,
        };
        this.userManager = new oidc_client_1.UserManager(settings);
    }
    AuthOIDService.prototype.getUser = function () {
        return this.userManager.getUser();
    };
    AuthOIDService.prototype.signinCallback = function () {
        return this.userManager.signinRedirectCallback();
    };
    AuthOIDService.prototype.signin = function () {
        return this.userManager.signinRedirect();
    };
    AuthOIDService.prototype.renewToken = function () {
        return this.userManager.signinSilent();
    };
    AuthOIDService.prototype.signout = function () {
        return this.userManager.signoutRedirect();
    };
    AuthOIDService.prototype.clearState = function () {
        return this.userManager.clearStaleState();
    };
    return AuthOIDService;
}());
exports.AuthOIDService = AuthOIDService;
exports.authService = new AuthOIDService();
